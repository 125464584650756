<template>
  <base-layout>
  <header-bar></header-bar>
    <IonSegment :value="activeDay" scrollable style="justify-content: space-between">
      <IonSegmentButton v-for="(value, key) in days" :key="key"  :value="key" style="min-width:250px" mode="md" @click="activeDay = key">{{value.label}}</IonSegmentButton>
    
    </IonSegment>
    <ion-content>
      <ion-list class="wcpt-session-list" v-if="activeDay">
        <!--div class="no-results-message" v-if="!checkIfMeetings(activeDay)"> There are no meetings scheduled for this day </div-->
          <div v-for="appointment in appointmentsByRequester" :key="appointment.id"> <!--session seran los meetings y days el array de los meetings-->
            <router-link v-if="appointment.start_at.slice(0,10)==activeDay" :to="'/app/videoroom/' + appointment.id" :style="prepareAppointmentItemStyle(appointment.start_at, appointment.end_at)" class="wcpt-session-item"> <!--checkear este disabled!!!-->
            <template  v-if="(checkRunningHour(appointment.start_at, appointment.end_at))">
              <span class="available-dot">  </span>   
             <img class="picture" src="@/assets/icon/icon.png">
            </template>
            <template  v-else>
             <img class="picture" src="@/assets/icon/icon.png" style="filter: grayscale(100%); opacity: 95%">
            </template>

             <div class="head">  
                <div class="type">{{ appointment.subject }}</div>
                <div class="time">{{ moment(appointment.start_at).utc(false).format('DD-MM-YYYY HH:mm') }} -
                 {{ moment(appointment.end_at).utc(false).format('HH:mm') }}
                </div>
              </div>
              <div class="list-meeting-link" style="background:grey !important" v-if="!checkRunningHour(appointment.start_at, appointment.end_at)"> Not Current</div>
              <div class="list-meeting-link" v-else> Join meeting </div>

              <div class="content">
                <div class="title">{{ appointment.description }}</div>
                <b>Consultant: </b> 
                {{checkRunningHour(date, endDate)}}
                <div class="speaker"> {{ appointment.consultant.firstname }} {{ appointment.consultant.lastname }} </div>
              </div>

                        
            </router-link>
            
          </div>

      </ion-list>
    </ion-content>
  </base-layout>
</template>

<script>
import {IonContent, IonList, IonSegment, IonSegmentButton} from '@ionic/vue';
import {defineComponent} from 'vue';
import {menuController} from "@ionic/vue";
import {mapActions, mapGetters, mapState} from 'vuex';
import moment from 'moment';
import config from '../../config'



export default defineComponent({
  name: 'Home',
  data() {
    return {
      //appointmentsListRequester: [],
      days: {},
      activeDay : '',
      conferenceId : config.conference,
      user: null,
      noAppointments: false,
      minAppointment: false,
      meetingCounter: 0,
    }
  },
  components: {
    IonContent,
    IonList,
    IonSegment,
    IonSegmentButton
  },
  computed: {
    ...mapState('sync', ['updateFinished']),
     ...mapState('auth', ['user']),

    ...mapGetters('appointment',['appointmentsByRequester']),
   
    
  },
  methods: {
    ...mapActions('appointment', ['getAppointmentsByRequester']),
    ...mapActions('sessions', ['getDays']),
     ...mapActions('auth', ['GET_USERDATA']),
    
    openMenu() {
      menuController.open("app-menu")
    },


    setActiveDayForToday(){ 
      for(let day in this.days){
        if (day==moment().format('YYYY-MM-DD')){ 
        this.activeDay = day;
        }
      }

      if(this.activeDay!=null){
        let daysArray=[];
          for(let d in this.days){
            daysArray.push(d);
          }
          this.activeDay=daysArray[0]

          }
        
    },

  

    checkRunningHour(date, endDate){
      
      let now = moment().utc(true).toISOString()
      let start = moment(date).toISOString()
      let end = moment(endDate).toISOString()
     
     if(moment(now).isBetween(moment(start), moment(end), 'minute')){
      return true;
     } else {
       return false;
     }
    },

     prepareAppointmentItemStyle(start_at, end_at){
        let styles = {
          
        };
        if(!this.checkRunningHour(start_at, end_at)){ //SWAP ! FOR TESTING
          styles = {
            'pointer-events': 'none',
            'border-color' : 'grey !important',
          }
        }
        console.log(styles)
        return styles;
      },


  },


  async created() { 
     if(localStorage.getItem('x-api-key') != ''){     
         
    let userRequest = await this.$store.dispatch('auth/GET_USERDATA');
    this.user = userRequest.data
   await this.getAppointmentsByRequester({"ConferenceId":this.conferenceId,"UserId":this.user.id}); 
     }
    this.moment = moment;

    this.days = await this.getDays();


   this.setActiveDayForToday();
  },

});
</script>
<style lang="scss">

</style>